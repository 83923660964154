import { useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
import PersonIcon from '@mui/icons-material/Person';
import PinDropIcon from '@mui/icons-material/PinDrop';
import SellIcon from '@mui/icons-material/Sell';
import axios from "axios";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import TableContainer from "./TableContainer";
import { StickyTable } from "react-sticky-table";
import { get_company_address } from "../services/configuration/configuration";
import icondelete from "../imgComponents/aaa.png";
import datacompany from "./company.json";

export const FORM_ORDERS_OPERATIONS = (props) => {


  console.log(props?.row?.original?.rfc)

  const formas_pago = [
    { "value": "", "label": "SELECCIONA UNA FORMA DE PAGO" },
    { "value": "PUE", "label": "CONTADO (PUE)" },
    { "value": "PPD", "label": "CREDITO (PPD)" }
  ]

  const metodos_pago = [
    {
      "id": 0,
      "value": "00",
      "label": "SELECCIONA"
    },
    {
      "id": 2,
      "value": "01",
      "label": "EFECTIVO (01)"
    },
    {
      "id": 3,
      "value": "02",
      "label": "CHEQUE NOMINATIVO (02)"
    },
    {
      "id": 4,
      "value": "03",
      "label": "TRANSFERENCIA ELECTRÓNICA DE FONDOS (03)"
    },
    {
      "id": 5,
      "value": "04",
      "label": "TARJETA DE CRÉDITO (04)"
    },
    {
      "id": 6,
      "value": "05",
      "label": "MONEDERO ELECTRÓNICO (05)"
    },
    {
      "id": 7,
      "value": "06",
      "label": "DINERO ELECTRÓNICO (06)"
    },
    {
      "id": 8,
      "value": "08",
      "label": "VALES DE DESPENSA (08)"
    },
    {
      "id": 9,
      "value": "12",
      "label": "DACIÓN DE PAGO (12)"
    },
    {
      "id": 10,
      "value": "13",
      "label": "PAGO POR SUBROGACIÓN (13)"
    },
    {
      "id": 11,
      "value": "14",
      "label": "PAGO POR CONSIGNACIÓN (14)"
    },
    {
      "id": 12,
      "value": "15",
      "label": "CONDONACIÓN (15)"
    },
    {
      "id": 13,
      "value": "17",
      "label": "COMPENSACIÓN (17)"
    },
    {
      "id": 14,
      "value": "23",
      "label": "NOVACIÓN (23)"
    },
    {
      "id": 15,
      "value": "24",
      "label": "CONFUSIÓN (24)"
    },
    {
      "id": 16,
      "value": "25",
      "label": "REMISIÓN DE DEUDA (25)"
    },
    {
      "id": 17,
      "value": "26",
      "label": "PRESCRIPCIÓN O CADUCIDAD (26)"
    },
    {
      "id": 18,
      "value": "27",
      "label": "A SATISFACCIÓN  DEL ACREEDOR (27)"
    },
    {
      "id": 19,
      "value": "28",
      "label": "TARJETA DE DÉBITO (28)"
    },
    {
      "id": 20,
      "value": "29",
      "label": "TARJETA DE SERVICIOS(29)"
    },
    {
      "id": 21,
      "value": "30",
      "label": "APLICACIÓN DE ANTICIPOS (30)"
    },
    {
      "id": 22,
      "value": "31",
      "label": "INTERMEDIARIO PAGOS (31)"
    },
    {
      "id": 23,
      "value": "99",
      "label": "POR DEFINIR (99)"
    }
  ]

  const [fechaRec, setFechaRec] = useState(props?.operation === "DETAILS" ? props?.row?.original?.fecha_rec: "");
  const [rfc, setRfc] = useState(props?.operation === "DETAILS" ? props?.row?.original?.rfc : "");
  const [nombre, setNombre] = useState(props?.operation === "DETAILS" ? props?.row?.original?.nombre : "");
  const [calle, setCalle] = useState(props?.operation === "DETAILS" ? props?.row?.original?.direccion_entrega?.calle : datacompany[0]?.street);
  const [num, setNum] = useState(props?.operation === "DETAILS" ? props?.row?.original?.direccion_entrega?.num : datacompany[0]?.outdoor_number);
  const [colonia, setColonia] = useState(props?.operation === "DETAILS" ? props?.row?.original?.direccion_entrega?.colonia : datacompany[0]?.neighborhood);
  const [ciudad, setCiudad] = useState(props?.operation === "DETAILS" ? props?.row?.original?.direccion_entrega?.ciudad : datacompany[0]?.city);
  const [estado, setEstado] = useState(props?.operation === "DETAILS" ? props?.row?.original?.direccion_entrega?.estado : datacompany[0]?.state);
  const [pais, setPais] = useState(props?.operation === "DETAILS" ? props?.row?.original?.direccion_entrega?.pais : datacompany[0]?.country);
  const [total, setTotal] = useState(props?.operation === "DETAILS" ? props?.row?.original?.total : 0.0);
  const [formaPago, setFormaPago] = useState(props?.operation === "DETAILS" ? props?.row?.original?.forma_pago : "");
  const [metodoPago, setMetodoPago] = useState(props?.operation === "DETAILS" ? props?.row?.original?.metodo_pago : "");
  const [moneda, setMoneda] = useState("MXN");
  const [descripcion, setDescripcion] = useState("");
  const [cantidad, setCantidad] = useState(0.0);
  const [unidad, setUnidad] = useState("");
  const [precioU, setPrecioU] = useState(0.0);
  const [noIdentificacion, setNoIdentificacion] = useState("");
  const dialog = useDialog();
  const [section, setsection] = useState(1);
  const [datass, setdatass] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [check, setcheck] = useState(false);
  const [concept_list, setconcept_list] = useState(props?.operation === "DETAILS" ? props?.row?.original?.detalles : []);

  useEffect(() => {
    handle_supplier();
    //handle_company();
  }, [])

  const handle_company = async()=>{
    try{
      const d = get_company_address();
      console.log(d);
    }catch(err){
      console.log(err);
    }
  }

  const handle_supplier = async() =>{
      axios
        .get(
          `https://8qpng1m0fj.execute-api.us-east-1.amazonaws.com/development/businesspartners`,
          {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },  
          }
        )
        .then((response) => {
          console.log(response);
          setdatass(response?.data?.business_partner);
        }).catch((err)=>{
          console.log("error al consulta");
        });
  }


  const change_select_partner = (item) =>{
    console.log(item);
    setRfc(item?.rfc ? item.rfc : "");
  }

  const delete_item = (row) => {

    console.log(row);

    const restart = Number(row.original.cantidad) * Number(row?.original?.precio_unitario);
    const resd = total - restart;
    setTotal(resd);
    setconcept_list(concept_list.filter(item =>item.descripcion !== row?.original.descripcion));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acctiones",
        Cell: ({ valor, row }) => {
          return (
            <div className="" style={{textAlign:"center", margin: "0 auto"}}>
              <img
                onClick={()=>delete_item(row)}
                className="delete-orders-crud-form" 
                alt="delete-icon"
                src={icondelete}
              ></img>
            </div>
          )
        }
      },
      {  
        Header: "Descripción",
        accessor: "descripcion",
        id: "description"
      },
      {
        Header: "Cantidad",
        accessor: "cantidad",
        id: "count"
      },
      {
        Header: "Precio Unitario",
        accessor: "precio_unitario",
        id: "price"
      },
      {
        Header: "Unidad",
        accessor: "unidad",
        id: "unit"
      },
      {
        Header: "No. Identificación",
        accessor: "no_identificacion",
        id: "no_identification"
      }
    ],
    [concept_list],
);

    const handle_add_item = () =>{
      
      const d = {
        descripcion: descripcion,
        cantidad: Number(cantidad),
        unidad: unidad,
        precio_unitario: Number(precioU),
        no_identificacion: noIdentificacion
      }

      var tt = cantidad * precioU;
      tt = tt + total; 
      setTotal(tt);

      setconcept_list([...concept_list, d]);
      setDescripcion("");
      setCantidad("");
      setUnidad("");
      setPrecioU("");
      setNoIdentificacion("");
    }

    const register = () =>{

    }
    
    const update = () =>{
      
    }
  

    
    
  return (
    <div className="form-demo">
      <div className="sections-form-crud">
        <div 
          className={section === 1 ? "section-infogeneral-order selec3oc": "section-infogeneral-order"}
          onClick={()=>setsection(1)}
        >
          <PersonIcon></PersonIcon>
          <p>Información General</p>
        </div>
        <div 
          className={section === 2 ? "section-address-order selec3oc": "section-address-order"}
          onClick={()=>setsection(2)}
        >
          <PinDropIcon></PinDropIcon>
          <p>Dirección</p>
        </div>
        <div 
          className={section === 3 ? "section-concepts-order selec3oc": "section-concepts-order"}
          onClick={()=>setsection(3)}
        >
          <SellIcon></SellIcon>
          <p>Conceptos</p>
        </div>
      </div>
      <div className="sections-form-items">
      {
        section === 1 ?
        <>
        <div className="grid-form-order-1">
            <div className="gfi1">
            {
              props?.operation === "DETAILS"?
              <TextField 
                label="Nombre" 
                required 
                value={nombre} 
                onChange={(e) => setNombre(e.target.value)} 
                sx={{ m: 1.5, width: "100%" }} 
                InputLabelProps={{ shrink: true }}
                disabled
              />:
              <Autocomplete
                options={datass}
                getOptionLabel={(option) => `${option.code} - ${option.business_partner}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => 
                  change_select_partner(newValue)
                }
                renderInput={(params) => <TextField {...params} label="Selecciona un Proveedor" sx={{ m: 1.5, width: "100%" }} />}
              />
            }
            </div>
            <div className="gfi2">
              <TextField 
                label="Fecha Rec" 
                type="date" 
                required 
                value={fechaRec} 
                onChange={(e) => setFechaRec(e.target.value)} 
                sx={{ m: 1.5, width: "100%"}} 
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="gfi3">
              <TextField 
                label="RFC" 
                required 
                disabled
                value={rfc} 
                onChange={(e) => setRfc(e.target.value)} 
                sx={{ m: 1.5, width: "100%" }} 
                InputLabelProps={{ shrink: true }}
              />
            </div>
        </div>
        <Box>
          <Button
              variant="contained"
              style={{"backgroundColor":"#3c84a4"}}
              sx={{ m: 1.5, width: "35ch" }}
              onClick={()=>setsection(2)}
            >
            Siguiente
          </Button>
          <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "35ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
        </Box>
        </>
        :
        section === 2 ?
        <div className="">
            {/* <TextField label="Fecha Doc" required value={fechaDoc} onChange={(e) => setFechaDoc(e.target.value)} sx={{ m: 1.5, width: "100%" }} /> */}
            {/* automatico con opcion de edicion */}
            <div className="grid-form-order-3">
              <div className="gfi5">
                <TextField 
                  label="Calle" 
                  value={calle} 
                  onChange={(e) => setCalle(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }} 
                  InputLabelProps={{ shrink: true }}
                />
              </div> 
              <div className="gfi6">
                <TextField 
                  label="Número" 
                  required 
                  value={num} 
                  onChange={(e) => setNum(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }} 
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="gfi7">
                <TextField 
                  label="Colonia" 
                  required 
                  value={colonia} 
                  onChange={(e) => setColonia(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }} 
                  InputLabelProps={{ shrink: true }}
              
                />
            </div>
            </div>
            <div className="grid-form-order-3">
              <div className="gfi8">
                <TextField 
                  label="Ciudad" 
                  required 
                  value={ciudad} 
                  onChange={(e) => setCiudad(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }}   
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="gfi9">
                <TextField 
                  label="Estado" 
                  required 
                  value={estado} 
                  onChange={(e) => setEstado(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }}   
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="gfi10">
                <TextField 
                  label="País" 
                  required 
                  value={pais} 
                  onChange={(e) => setPais(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }}  
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>  
        <Box>
          <Button
              variant="contained"
              style={{"backgroundColor":"#3c84a4"}}
              sx={{ m: 1.5, width: "35ch" }}
              onClick={()=>setsection(3)}
            >
            Siguiente
          </Button>
          <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "35ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
        </Box>
        
        </div>:
        section === 3 ? 
        <div className="">
            <div className="grid-form-order-4">
              <div className="gfi20">
                <TextField 
                  label="Total" 
                  required 
                  disabled
                  value={total} 
                  onChange={(e) => setTotal(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }} 
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="gfi21">
                <TextField 
                  select
                  label="Forma de Pago" 
                  required 
                  SelectProps={{native: true}}
             
                  value={formaPago} 
                  onChange={(e) => setFormaPago(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }} 
                  InputLabelProps={{ shrink: true }}
                >
                  {
                    formas_pago?.map((item, index)=>(
                      <option key={index} value={item.value}>{item.label}</option>
                    ))
                  }
                 </TextField>
              </div>
              <div className="gfi22">
                <TextField
                  select 
                  label="Método de Pago" 
                  required 
                  value={metodoPago} 
                  onChange={(e) => setMetodoPago(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }} 
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{native: true}}
             
                >
                  {
                    metodos_pago?.map((item, index)=>(
                      <option key={index} value={item.value}>{item.label}</option>
                    ))
                  }
                </TextField>
              </div>
              <div className="gfi23">
                <TextField 
                  label="Moneda" 
                  required 
                  disabled
                  value={moneda} 
                  onChange={(e) => setMoneda(e.target.value)} 
                  sx={{ m: 1.5, width: "100%" }}   
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className="area-items">
              <div className="grid-form-order-5">
                <div className="gfi30">
                  <TextField 
                    label="Descripción" 
                    required 
                    value={descripcion} 
                    onChange={(e) => setDescripcion(e.target.value)} 
                    sx={{ m: 1.5, width: "100%" }} 
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="gfi31">
                  <TextField 
                    label="Precio Unitario" 
                    required 
                    type="number"
                    value={precioU} 
                    onChange={(e) => setPrecioU(e.target.value)} 
                    sx={{ m: 1.5, width: "100%" }} 
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="gfi32">
                  <TextField 
                    label="Cantidad" 
                    required 
                    type="number"
                    value={cantidad} 
                    onChange={(e) => setCantidad(e.target.value)} 
                    sx={{ m: 1.5, width: "100%" }} 
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className="grid-form-order-6">
                <div className="gfi33">
                  <TextField 
                    label="Unidad" 
                    required 
                    value={unidad} 
                    onChange={(e) => setUnidad(e.target.value)} 
                    sx={{ m: 1.5, width: "100%" }} 
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="gfi34">
                  <TextField 
                    label="No. Identificación" 
                    value={noIdentificacion} 
                    onChange={(e) => setNoIdentificacion(e.target.value)} 
                    sx={{ m: 1.5, width: "100%" }} 
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="gfi35">
                  <button
                    onClick={()=>handle_add_item()}
                  >Agregar Detalles</button>
                </div>
              </div>
            </div>
            <div className="table-concepts-orders" style={{ width: "100%"}}>
              <StickyTable>
                <TableContainer
                    paginado={"Ordenes de Compra"}
                    consultas={"Ordenes de Compra"}
                    columns={columns}
                    data={concept_list}
                    pagination={true}
              />
              </StickyTable>
            </div>
            <Box>
            <Button
                variant="contained"
                style={{"backgroundColor":"#3c84a4"}}
                sx={{ m: 1.5, width: "35ch" }}
                onClick={()=>{props?.operation === "DETAILS" ? update(): register()}}
              >
                {
                  props?.operation === "DETAILS" ? "Actualizar" : "Registrar" 
                }
            </Button>
            <Button
                style={{"backgroundColor":"red"}}
                variant="contained"
                sx={{ m: 1.5, width: "35ch" }}
                onClick={() => {
                    dialog.close();
                  } 
                }
              >
              cancelar
            </Button>
          </Box>
        </div>:
        <></>
      }
      </div>
    </div>
  )
}
