import { useMemo, useState } from "react";
import pdf1 from "./ORDEN.pdf";
import pdf2 from "./factura.pdf";

import TableContainer from "./TableContainer";
import { StickyTable } from "react-sticky-table";
import { Box, Button, TextField } from "@mui/material";

export const CONFIRM_ORDER = (props) => {
    
    const[type_doc, settype_doc] = useState(1);
    const[views, setviews] = useState(1);

    const columns = useMemo(
        () => [
          {  
            Header: "Descripción",
            accessor: "descripcion",
            id: "description"
          },
          {
            Header: "Cantidad",
            accessor: "cantidad",
            id: "count"
          },
          {
            Header: "Precio Unitario",
            accessor: "precio_unitario",
            id: "price"
          },
          {
            Header: "Unidad",
            accessor: "unidad",
            id: "unit"
          },
          {
            Header: "No. Identificación",
            accessor: "no_identificacion",
            id: "no_identification"
          }
        ],
        [],
    );
    
  return (
    <div>
        <div className="content-dashboard-order">
            <div className="content-sction-dashboard-a">
                {
                    type_doc == 3 ?
                    <iframe
                        src={pdf1}
                    ></iframe>
                :
                <>
                <h3>Información Proveedor: </h3>
                <div className="info-supplier">
                    <p><b>Orden: </b>{props?.row?.original?.order_id}</p>
                    <p><b>Fecha Recepción: </b>{props?.row?.original?.fecha_rec}</p>
                    <p><b>Fecha de Documento: </b>{props?.row?.original?.fecha_doc}</p>
                    <p><b>Nombre proveedor: </b>{props?.row?.original?.nombre}</p>
                    <p><b>RFC: </b>{props?.row?.original?.rfc}</p>
                </div>
                <h3>Dirección Entrega:</h3>
                <div className="info-supplier">
                    <p><b>calle: </b>{props?.row?.original?.direccion_entrega?.calle}</p>
                    <p><b>Número: </b>{props?.row?.original?.direccion_entrega?.num}</p>
                    <p><b>Colonia: </b>{props?.row?.original?.direccion_entrega?.colonia}</p>
                    <p><b>Ciudad: </b>{props?.row?.original?.direccion_entrega?.ciudad}</p>
                    <p><b>Estado: </b>{props?.row?.original?.direccion_entrega?.estado}</p>
                </div>
                <h3>Detalles: </h3>
                <div className="table-details-orders">
                    <StickyTable>
                        <TableContainer
                            paginado={"Ordenes de Compra"}
                            consultas={"Ordenes de Compra"}
                            columns={columns}
                            data={props?.row?.original?.detalles}
                            pagination={true}
                    />
                    </StickyTable>
                </div>
                </>
                }
            </div>
            <div className="content-sction-dashboard-b">
                <div className="sections-pdfs-views">
                    <TextField
                        select 
                        label="Tipo de Documento" 
                        required 
                        value={type_doc} 
                        onChange={(e) => settype_doc(e.target.value)} 
                        sx={{ m: 1.5, width: "100%" }} 
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{native: true}}
                    >
                        <option key={1} value={1}>Orden de Compra</option>
                        <option key={2} value={2}>Factura</option>
                        <option key={3} value={3}>Ambos</option>
                        
                    </TextField>
                </div>
                {
                    type_doc == 1?
                        <iframe
                            src={pdf1}
                        ></iframe>:
                    type_doc == 2 ?
                        <iframe
                            src={pdf2}
                        ></iframe>:
                    type_doc == 3?
                        <iframe
                            src={pdf2}
                        ></iframe>:
                    <></>
                }        
            </div>
        </div>
        <div className="buttons-sections-validate">
        <Box>
            <Button
                variant="contained"
                style={{"backgroundColor":"#3c84a4"}}
                sx={{ m: 1.5, width: "35ch" }}
                onClick={()=>{}}
              >
                Autorizar
            </Button>
            <Button
                style={{"backgroundColor":"red"}}
                variant="contained"
                sx={{ m: 1.5, width: "35ch" }}
                onClick={() => {}}
              >
              Rechazar
            </Button>
          </Box>
        </div>
    </div>
  )
}
