import { useEffect, useMemo, useRef, useState } from 'react';
import img1 from "../imgComponents/xml-icon.png";
import img2 from "../imgComponents/pdf dowload.png";
import "./order.css";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import TableContainer from "./TableContainer";
import datainvoices from "./invoices.json";
import dataorders from "./order_2.json";
import { StickyTable } from "react-sticky-table";
import { Checkbox } from "@mui/material";

export const DEMO_ORDER = () => {

  
  const [data, setData] = useState(dataorders);
    const [isLoading, setIsLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [reconsult, setreconsult] = useState(false);
    const notificationSystemRef = useRef();
    const [rfc, setRFC] = useState("");
    const [sdn, setSDN] = useState("");
    const [cancel, setCancel] = useState("all");
    const [tipodoc, setTipoDoc] = useState("null");
    const [modalT, setmodalT] = useState(false);
    const [menssage, setmenssage] = useState("");
    const [modalGeneral, setmodalGeneral] = useState(false);
    const [total_rows, settotal_rows] = useState("");

    const [process, setprocess]= useState(1);
    const fileInputRef = useRef(null);
    const [file, setfile]=useState(null);
    const [errmsjf, seterrmsjf]=useState("");
    const [errfile, seterrfile] = useState(false);

    const [filePdf, setFilePdf] = useState(null);
    const [fileXml, setFileXml] = useState(null);
    const [errMsj, setErrMsj] = useState("");
    const [highlight, setHighlight] = useState(false);
    const fileInputRefPdf = useRef(null);
    const fileInputRefXml = useRef(null);

    const [open, setopen]=useState(false);
    const [data_invoces, setdata_invoices] = useState(datainvoices);
    const [selectedRow, setSelectedRow] = useState(null);

  
    const handleFiles = (files, type) => {
      setErrMsj("");
      for (const file of files) {
        if (type === 'pdf' && file.type === 'application/pdf') {
          setFilePdf(file);
        } else if (type === 'xml' && file.type === 'text/xml') {
          setFileXml(file);
        } else {
          setErrMsj("Solo se permiten archivos PDF o XML según el apartado correspondiente");
        }
      }
    };
    
    const columns_invoices = useMemo(
      () => [
    
        {
          id: "invoice_date",
          Header: "Fecha",
          accessor: "invoice_date",
        },
  
        {
          Header: "Serie",
          accessor: "serie",
          id: "serie",
        },
        {
          id: "folio",
          Header: "Folio",
          accessor: "folio",
        },
        {
          Header: "Cliente",
          accessor: "name_business_partner",
          id: "name_business_partner"
        },
        {
          Header: "RFC",
          accessor: "rfc_business_partner",
          id: "rfc_business_partner"
        },
  
        {
          id : "subtotal",
          Header: "Subtotal",
          accessor: "subtotal",
        },
        {
          id: "discount",
          Header: "Descuento",
          accessor: "discount",
        },
        {
          id: "tax",
          Header: "Impuesto",
          accessor: "tax",
        },
        {
          id: "total",
          Header: "Total",
          accessor: "total"
        },
  
        {
          Header: "Método de pago",
          accessor: "payment_method",
          id: "payment_method", 
          filterVariant: 'multi-select',
        },
        {
          Header: "Forma de Pago",
          accessor: "forma_pago",
          id: "forma_pago", 
          filterVariant: 'multi-select',
        },
        {
          id: "tipodecomprobante",
          Header: "Tipo de comprobante",
          accessor: "tipodecomprobante",
          filterVariant: 'multi-select',
          
        },
        {
          id: "cancel",
          Header: "Estado",
          accessor: "cancel",
          filterVariant: 'multi-select',
          accessorFn: (d) => {
            return d.cancel === true ? "CANCELADO" : "VIGENTE";
          },
        },
        {
          id: "uuid_sat",
          Header: "UUID_SAT",
          accessor: "uuid_sat",        
        },
        {
          Header: "Usuario",
          accessor: "username",
          id: "username"
        },
      ],[]
    )

    const handleCheckboxChange = (code) => {
      console.log(selectedRow);
      console.log(code);
      
      if(Number(code) === Number(selectedRow)){
        console.log("son iguales");
        setSelectedRow(null);
      }else{
        setSelectedRow(code);
        console.log("no son iguales");
      }
    };  

    const columns = useMemo(
        () => [
            {
              Header: "",
              id: "selection",
              Cell: ({ row }) => {
                return (
                  <input
                    type="checkbox"
                    checked={selectedRow === row?.original?.code}
                    onChange={() => handleCheckboxChange(row.original.code)}
                  />
                );  
              },
            },
          {
            Header: "No. Orden",
            accessor: "code",
            id: "code"
          },
          {
            Header: "Fecha Documento",
            accessor: "fecha_doc",
            id: "fecha_doc"
          },
          {
            Header: "Fecha Recepción",
            accessor: "fecha_rec",
            id: "fecha_rec"
          },
          {
            Header: "Total",
            accessor: "total",
            id: "total"
          },
          {
            Header: "Forma de Pago",
            accessor: "forma_pago",
            id: "forma_pago"
          },
          {
            Header: "Método de Pago",
            accessor: "metodo_pago",
            id: "metodo_pago"
          },
          {
            Header: "Moneda",
            accessor: "moneda",
            id: "moneda"
          },
          {
            Header: "Factura",
            accessor: "factura",
            id: "factura"
          },
          {
            Header: "RFC PROVEEDOR",
            accessor: "rfc",
            id: "rfc"
          },
          {
            Header: "NOMBRE PROVEEDOR",
            accessor: "nombre",
            id: "nombre"
          }
        ],
        [selectedRow],
    );
    
    const handleDrop = (e, type) => {
      e.preventDefault();
      setHighlight(false);
      const files = e.dataTransfer.files;
      handleFiles(files, type);
    };
    
    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    
    const handleFileInputClick = (type) => {
      if (type === 'pdf') fileInputRefPdf.current.click();
      else if (type === 'xml') fileInputRefXml.current.click();
    };
    
    const handleFileInputChange = (e, type) => {
      const files = e.target.files;
      handleFiles(files, type);
    };
  
    const send_invoices = () =>{
      setprocess(2);
    }

  return (
    <div>

{
      process === 1 ?
      <div className='container-upload-xmlpdf'>
        <div className='text-upload-file-xmlpdf'>
          <p><b>Instrucciones:</b></p>
          <p>1. Arrastre o seleccione el PDF/XML a enviar en su apartado correspondiente.</p>
          <p>2. Haga clic en el botón de enviar para subir su documento.</p>
        </div>

          <div className='upload_xml_pdf_order'>
            <div 
              className={`drop-area ${highlight ? 'highlight' : ''}`} 
              onDrop={(e) => handleDrop(e, 'pdf')} 
              onDragOver={preventDefaults}
              onDragEnter={() => setHighlight(true)}
              onDragLeave={() => setHighlight(false)}
              onClick={()=>handleFileInputClick('pdf')}
            >
              <div className="icon-upload-xmlpdf">
                <img src={img2} alt='icon-pdf'></img>
              </div>
              <p>Arrastre o seleccione el PDF a enviar.</p>
              {filePdf && <p><b>Archivo seleccionado:</b> {filePdf.name}</p>}
              <input 
                type="file" 
                ref={fileInputRefPdf}
                accept="application/pdf" 
                style={{ display: "none" }} 
                onChange={(e) => handleFileInputChange(e, 'pdf')} />
            </div>
            <div 
              className={`drop-area ${highlight ? 'highlight' : ''}`}
              onDrop={(e) => handleDrop(e, 'xml')} 
              onDragOver={preventDefaults}
              onDragEnter={() => setHighlight(true)}
              onDragLeave={() => setHighlight(false)}
              onClick={()=>handleFileInputClick('xml')}
            >
              <div className="icon-upload-xmlpdf">
                <img src={img1} alt='icon-xml' width={"100px"}></img>
              </div>
              <p>Arrastre o seleccione el XML a enviar.</p>
              {fileXml && <p><b>Archivo seleccionado:</b> {fileXml.name}</p>}
              <input 
                  type="file" 
                  ref={fileInputRefXml} 
                  accept="text/xml" 
                  style={{ display: "none" }} 
                  onChange={(e) => handleFileInputChange(e, 'xml')}
                />
            </div>
          </div>
          {errmsjf.length > 0 ? 
            <div className="info-box">
              <NotificationImportantIcon></NotificationImportantIcon>
              <p style={{ color: "red" }}>{errmsjf}</p>
            </div>
            :<div className="info-box">
              <NotificationImportantIcon></NotificationImportantIcon>
              <p>El proceso es exclusivamente para subir facturas de proveedores.</p>
            </div>
          }
          <div className='upload_smlpdf-content-botton'>
            <button
              onClick={()=>{send_invoices()}}
            >ENVIAR</button>

          </div>
            
      </div>:
      process == 2 ?
        <div className='new_table_v2' style={{"width":"90%", margin: "0 auto"}}>
        <h2>Factura</h2>
         <StickyTable>
          <TableContainer
                paginado={"Facturas"}
                consultas={"Facturas Consultadas"}
                columns={columns_invoices}
                data={data_invoces}
                pagination={false}
          />  
         </StickyTable>
        <h2>Ordenes de Compra</h2>
          <StickyTable>
            <TableContainer
                paginado={"Ordenes de Compra"}
                consultas={"Ordenes de Compra"}
                columns={columns}
                data={data}
                pagination={true}
          />
          </StickyTable>
          <div className="upload-relation-orderinvoice">
              <button>No Relacionar</button>
              <button>Relacionar</button>    
          </div>
        </div>:
        <></>
    }
    </div>
  )
}
