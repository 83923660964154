import { useMemo, useState } from "react";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import TableContainer from "./TableContainer";
import { StickyTable } from "react-sticky-table";
import CREATE from "../imgComponents/create.png";
import Delete from "../imgComponents/borrar.png";
import Details from "../imgComponents/save.png";
import pdficon from "../imgComponents/pdf.png";
import { FORM_ORDERS_OPERATIONS } from "./form_order";
import { Confirm, CustomDialog } from "react-st-modal";
import "./order.css";
import dataordenes from "./order.json";
import pdforder from "./ORDEN.pdf";
import lupa from "../imgComponents/lupa.png";
import { CONFIRM_ORDER } from "./confirm";


export const PURCHASE_ORDERS = () => {

        const [data, setdata] = useState(dataordenes);


        const delete_count = (item) =>{
          console.log(item);
        }

        const download_document_order = () =>{
          const link = document.createElement("a");
          link.href = pdforder;
          link.download = "Orden de Compra.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        const operations_details = (row) => {
          return (
            <>
              <img
                alt=""
                id="crearCCl"
                src={Details}
                onClick={async () => {
                    const result = await CustomDialog(               
                <div>
                    <FORM_ORDERS_OPERATIONS operation={"DETAILS"} row={row}/>
                </div>,
                    {
                    className: "modalTItle",
                    title:"Visualizar Detalles",
                    showCloseIcon: true,
                    isCanClose:false,
                    }
                  );
              }}
                title="Visualizar Detalles"
                className="actions-bottons-purchaseo"      
              ></img>
              &nbsp;
              <img
                alt=""
                id="crearCCl"
                src={pdficon}
                onClick={async () => {
                  download_document_order();    
                }}
                title="Descargar Orden de Compra"
                className="actions-bottons-purchaseo"      
              ></img>
              &nbsp;
              {
                row?.original?.status == "Pendiente" ? 
                <img
                  alt=""
                  id="crearCCl"
                  src={lupa}
                  onClick={async () => {
                      const result = await CustomDialog(               
                  <div>
                      <CONFIRM_ORDER 
                        row={row}
                      />
                  </div>,
                      {
                      className: "modalTItle",
                      title:"Verificar",
                      showCloseIcon: true,
                      isCanClose:false,
                      }
                    );
                }}
                  title="Verificar Orden de Compra"
                  className="actions-bottons-purchaseo"
                
                ></img>:<></>
              }
            </>
          )
        }

        const columns = useMemo(
            () => [
            {
                id: "Acciones", 
                Header: () =>(
                    <div className="boton-create-order-crud">
                        <img
                        alt=""
                        className="actions-bottons-purchaseo"
                        id="crearCCl"
                        src={CREATE}
                        onClick={async () => {
                            const result = await CustomDialog(               
                        <div>
                            <FORM_ORDERS_OPERATIONS operation={"CREATE"}/>
                        </div>,
                            {
                            className: "modalTItle",
                            title:"Registrar Orden de Compra",
                            showCloseIcon: true,
                            isCanClose:false,
                            }
                    );
                }}
                        title="Registrar Cuenta de Pago"
                        ></img>
                  </div> 
                ),
                Cell: ({ valor, row }) => {
                      console.log(row.original.status === "Abierta")

                      if(row?.original?.status === "Abierta"){
                        return (
                          <div>
                            <img
                              className="actions-bottons-purchaseo"
                              alt=""
                              src={Delete}
                              onClick={async() =>{
                                  const result = await Confirm('¿Esta usted seguro de cancelar la Orden de Compra?','Eliminar Orden de Compra','Si','No');
                                  if(result){
                                      delete_count(row);
                                    }
                                  }            
                                }
                              title="Cancelar Orden de Compra"
                            ></img>
                            {operations_details(row)}
                          </div>
                        )
                      }else{
                      return (
                        <>
                          {operations_details(row)}  
                        </>
                      )
                      }
                }},
              {
                Header: "No. Orden",
                accessor: "code",
                id: "code"
              },
              {
                Header: "Estado",
                accessor: "status",
                id: "status"
              },
              {
                Header: "Fecha Documento",
                accessor: "fecha_doc",
                id: "fecha_doc"
              },
              {
                Header: "Fecha Recepción",
                accessor: "fecha_rec",
                id: "fecha_rec"
              },
              {
                Header: "Total",
                accessor: "total",
                id: "total"
              },
              {
                Header: "Forma de Pago",
                accessor: "forma_pago",
                id: "forma_pago"
              },
              {
                Header: "Método de Pago",
                accessor: "metodo_pago",
                id: "metodo_pago"
              },
              {
                Header: "Moneda",
                accessor: "moneda",
                id: "moneda"
              },
              {
                Header: "Factura",
                accessor: "factura",
                id: "factura"
              },
              {
                Header: "RFC Proveedor",
                accessor: "rfc",
                id: "rfc"
              },
              {
                Header: "Nombre del Proveedor",
                accessor: "nombre",
                id: "nombre"
              },
            ],
            [],
        );
  
    return (
    <div>
        <div className="table_purshase_orders">
            <StickyTable>
                <TableContainer
                    paginado={"Ordenes de Compra"}
                    consultas={"Ordenes de Compra"}
                    columns={columns}
                    exportar={true}
                    data={data}
                    pagination={true}
            />
            </StickyTable>
        </div>
    </div>
  )
}
